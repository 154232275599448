// Import hooks
import React, { useContext, useEffect, useState } from "react";

// import context
import { DatosInicioContext } from "../../context/DatosInicioContext";
import { DarkModeContext } from "../../context/DarkModeContext";

// import libreria
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ThreeDots } from "react-loader-spinner";

// import components
import BienvenidoPanel from "../../components/BienvenidoPanel";
import DatosInicio from "../../components/DatosInicio";
import GraficaData from "../../components/GraficaData";
import GraficaDataCelular from "../../components/GraficaDataCelular";
import GraficaDataCelularDark from "../../components/GraficaDataCelularDark";
import ComportamientoGrafica from "../../components/ComportamientoGrafica";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import TituloPagina from "../../components/TituloPagina";
import Footer from "../../components/Footer";
import PopUpCalificar from "../../components/PopUpCalificar";

// import assets
import logo from "../../assets/img/logo.png";
import logoClaro from "../../assets/img/logo-modo-oscuro.png";
import PopUpNotificaciones from "../../components/PopUpNotificaciones";
import PopUpNotificaciones2 from "../../components/PopUpNotificaciones2";
import PopUpNotificacionesCarousel from "../../components/PopUpNotificacionesCarousel";

const Inicio = ({ califico, setCalifico }) => {
  // context para el modo claro/oscuro
  const { darkMode } = useContext(DarkModeContext);

  // de aqui saca los datos del context
  const { datosBackContext, codigoRespuesta, noHayDatos } =
    useContext(DatosInicioContext);

  // funcion para navegar con el hook useNavigate
  const navegacion = useNavigate();

  // estado para calificar
  const [estadoCalifico, setEstadoCalifico] = useState(null);

  // si modificas las fechas aqui dispara el modal pop up notificaciones
  // IMPORTANTE: respeta ese formato
  const fechasEspecificas = [
    // "2024-06-28",
    // "2024-06-29",
    // "2024-06-30",
    // "2024-07-01",
    // "2024-07-01",
    // "2024-07-02",
    // "2024-07-03",
    // "2024-07-04",
    // "2024-07-05",
    // "2024-07-06",
    // "2024-07-07",
    // "2024-07-08",
    // "2024-07-09",
    // "2024-07-10",
    // "2024-07-11",
    // "2024-07-12",
    // "2024-07-13",
    // "2024-07-14",
    // "2024-07-15",
    // "2024-07-16",
    // "2024-07-17",
    // "2024-07-18",
    // "2024-07-19",
    // "2024-07-20",
    // "2024-07-21",
    // "2024-07-22",
    // "2024-07-23",
    // "2024-07-24",
    // "2024-07-25",
    // "2024-07-26",
    // "2024-07-27",
    // "2024-07-28",
    // "2024-07-29",
    // "2024-07-30",
    // "2024-07-31",
    "2024-08-28",
    "2024-08-29",
    "2024-08-30",
    "2024-08-31",
    "2024-09-01",
    "2024-09-02",
    "2024-10-10",
    "2024-10-11",
    "2024-10-12",
    "2024-10-13"
  ];

  const [showModalNotificaciones, setShowModalNotificaciones] = useState(() => {
    const hoy = new Date().toISOString().slice(0, 10); // Formato 'YYYY-MM-DD'
    const fechaUltimoPopup = localStorage.getItem("fechaUltimoPopup");

    if (fechasEspecificas.includes(hoy) && fechaUltimoPopup !== hoy) {
      return true; // Mostrar si hoy está en las fechas especificadas y no se mostró hoy
    }
    return false; // No mostrar si hoy no está en las fechas o ya se mostró
  });

  const handleClose = () => {
    const hoy = new Date().toISOString().slice(0, 10);
    localStorage.setItem("fechaUltimoPopup", hoy); // Guardar hoy como la última fecha de visualización
    setShowModalNotificaciones(false);
  };
  // funcion para recargar la pagina al realizar la consulta
  // lo usamos para actualizar la pagina.
  function recargarPagina() {
    window.location.reload();
  }

  // url de las apis
  const apiUrlToken = process.env.REACT_APP_API_TOKEN;
  const apiUrlCalificoMes = process.env.REACT_APP_API_CALIFICO_MES;

  // estado para manejar el spinner de carga
  const [cargando, setCargando] = useState(false);

  // estado para el contador
  const [contadorModal, setContadorModal] = useState(0);

  // consulta a la base de datos para verificar el token y la autenticacion
  useEffect(() => {
    const verificarToken = async () => {
      const token = sessionStorage.getItem("token");
      setCargando(true);
      try {
        if (!token) {
          manejarRespuestaNoAutorizada();
          return;
        }

        const response = await fetch(apiUrlToken, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ Token: token }),
        });

        if (!response.ok) {
          manejarRespuestaNoAutorizada();
          return;
        }

        const data = await response.json();

        if (data === 0) {
          obtenerCalifico(token);
        } else {
          manejarAccesoDenegado();
        }
        setCargando(false);
      } catch (error) {}
      modalEmergente();
    };

    // este es el modal emergente para calificar una vez al mes
    const modalEmergente = () => {
      if (noHayDatos === false) {
        // Después de cargar los datos, verifica si hay datos disponibles

        // Aquí asumimos que 'datosBackContext' es el estado que contiene los datos
        Swal.fire({
          text: "Actualmente no hay datos disponibles para mostrar en el panel.",
          imageUrl: logo,
          imageWidth: 100,
          imageHeight: 30,
          imageAlt: "Logo",
          confirmButtonText: "Ok",
          timer: 12000,
          allowOutsideClick: true,
          customClass: {
            container: darkMode
              ? "swal2-container--dark--popup"
              : "swal2-container--light--popup",
            confirmButton: "my-swal-button",
          },
        });
      }
    };

    // funcion para redireccionar
    const redireccionar = () => {
      navegacion("/");
      recargarPagina();
    };

    const manejarRespuestaNoAutorizada = () => {
      sessionStorage.removeItem("token");

      Swal.fire({
        title: "Sesión expirada o token inválido.",
        text: "Inicie sesión nuevamente.",
        imageUrl: logo,
        imageWidth: 100,
        imageHeight: 30,
        imageAlt: "Logo",
        confirmButtonText: "Ok",
        timer: 5000,
        allowOutsideClick: true,
        customClass: {
          container: darkMode
            ? "swal2-container--dark"
            : "swal2-container--light",
          confirmButton: "my-swal-button",
        },
      }).then(redireccionar);
    };

    const manejarAccesoDenegado = () => {
      Swal.fire({
        title: "Acceso denegado.",
        text: "No tiene permisos para acceder.",
        imageUrl: logo,
        imageWidth: 100,
        imageHeight: 30,
        imageAlt: "Logo",
        confirmButtonText: "Ok",
        timer: 5000,
        allowOutsideClick: true,
        customClass: {
          container: darkMode
            ? "swal2-container--dark"
            : "swal2-container--light",
          confirmButton: "my-swal-button",
        },
      }).then(redireccionar);
    };

    // funcion para obtener la calificacion
    const obtenerCalifico = async (token) => {
      try {
        const response = await fetch(apiUrlCalificoMes, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ Token: token }),
        });

        if (response.ok) {
          const resultadoCalifico = await response.json();
          setEstadoCalifico(resultadoCalifico);
        } else {
        }
      } catch (error) {}
    };
    verificarToken();
    setContadorModal(contadorModal + 1);
  }, [noHayDatos]);

  if (cargando) {
    // Muestra el spinner mientras los datos se están cargando
    return (
      <div
        className="spinner-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div className="">
          <div>
            <img
              className="img-fluid logo-width-spinner"
              src={darkMode ? logoClaro : logo}
              alt="logo SOCO"
            />
          </div>
          <div className="ms-3">
            <ThreeDots
              visible={true}
              height="60"
              width="60"
              color="#b4c400 "
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="d-xl-block d-none mt-4 pt-4 ">
        <BienvenidoPanel translate="no" datos={datosBackContext} />
      </div>
      <ScrollToTopButton translate="no" />
      <div className="pt-2">
        <TituloPagina translate="no" title="Inicio" />
      </div>
      <div className="my-3">
        <DatosInicio translate="no" datos={datosBackContext} />
      </div>
      {/* aqui se utiliza el estado califico  */}
      {/* la idea es que este popUp sale una vez al mes  */}
      {/* cuando el estado de calificar esta en false  */}
      <article>
        {estadoCalifico === false && (
          <>
            <PopUpCalificar
              translate="no"
              califico={califico}
              setCalifico={setCalifico}
            />
          </>
        )}
      </article>
      <div>
        {/* MODAL NOTIFICACION SIMPLE Y MODAL CON CAROUSEL */}
        {showModalNotificaciones && (
          <PopUpNotificaciones translate="no" onClose={handleClose} />
          // <PopUpNotificacionesCarousel translate="no" onClose={handleClose} />
        )}
          {/* MODAL NOTIFICACION CON ANIMACIONESL */}
        {/* {showModalNotificaciones && (
      <PopUpNotificaciones2 translate="no" onClose={handleClose} />
    )} */}
      </div>
      <div className="pb-4">
        <ComportamientoGrafica translate="no" datos={datosBackContext} />
      </div>
      <div className="d-none d-xl-block">
        <GraficaData translate="no" datos={datosBackContext} />
      </div>
      <div className="d-xl-none d-block">
        {darkMode ? (
          <GraficaDataCelularDark translate="no" datos={datosBackContext} />
        ) : (
          <GraficaDataCelular translate="no" datos={datosBackContext} />
        )}
      </div>
      <div className="py-4">
        <Footer translate="no" />
      </div>
    </div>
  );
};

export default Inicio;
