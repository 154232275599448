import React, { useContext, useState, useEffect } from 'react';
import { DarkModeContext } from '../../context/DarkModeContext';
import { useNavigate } from 'react-router-dom';
import TituloPagina from '../../components/TituloPagina';
import BienvenidaFlor from '../../components/PanelFlorencia/BienvenidaFlor';
import ContenidoInicio from '../../components/PanelFlorencia/ContenidoInicio';
import Swal from 'sweetalert2';
import Footer from '../../components/Footer';
import logo from '../../assets/img/logo.png';
import Select from 'react-select'

const Inicio = () => {
    const { darkMode } = useContext(DarkModeContext);
    const [provincias,setProvincias]= useState([])
    const apiUrlToken = process.env.REACT_APP_API_TOKEN;
    const navegacion = useNavigate();
    const [cargando, setCargando] = useState(false);
    const apiUrlFacturacion = '/api/facturacion/facturacionpagina1'; // URL de la API de facturación diaria
    const [datosFacturacion, setDatosFacturacion] = useState([]); // Estado para almacenar datos de facturación
    const [provinciaSeleccionada, setProvinciaSeleccionada] = useState(null);
    // funcion para recargarPagina
    console.log(datosFacturacion)
    function recargarPagina() {
        window.location.reload();
    }

    useEffect(() => {
        const verificarToken = async () => {
            const token = sessionStorage.getItem('token');

            if (!token) {
                manejarRespuestaNoAutorizada();
                return;
            }
            setCargando(true);
            try {
                const response = await fetch(apiUrlToken, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ Token: token }),
                });

                if (response.ok) {
                    const data = await response.json();

                    if (data !== 5) {
                        manejarAccesoDenegado();
                    }
                } else {
                    if (response.status === 401) {
                        manejarRespuestaNoAutorizada();
                    } else {
                        console.error('Error inesperado al verificar token');
                    }
                }
            } catch (error) {
                console.error('Error al verificar el token:', error);
            }
            setCargando(false);
        };

        // funcion re direccionar
        const redireccionar = () => {
            navegacion('/');
            recargarPagina();
        };

        // funcion para manejar respuesta no autorizada
        const manejarRespuestaNoAutorizada = () => {
            sessionStorage.removeItem('token');

            Swal.fire({
                title: 'Sesión expirada o token inválido.',
                text: 'Inicie sesión nuevamente.',
                imageUrl: logo,
                imageWidth: 100,
                imageHeight: 30,
                imageAlt: 'Logo',
                confirmButtonText: 'Ok',
                timer: 5000,
                allowOutsideClick: true,
                customClass: {
                    container: darkMode
                        ? 'swal2-container--dark'
                        : 'swal2-container--light',
                    confirmButton: 'my-swal-button',
                },
            }).then(redireccionar);
        };

        // funcion para manejar acceso denegado
        const manejarAccesoDenegado = () => {
            Swal.fire({
                title: 'Sesión expirada o token inválido.',
                text: 'Inicie sesión nuevamente.',
                imageUrl: logo,
                imageWidth: 100,
                imageHeight: 30,
                imageAlt: 'Logo',
                confirmButtonText: 'Ok',
                timer: 5000,
                allowOutsideClick: true,
                customClass: {
                    container: darkMode
                        ? 'swal2-container--dark'
                        : 'swal2-container--light',
                    confirmButton: 'my-swal-button',
                },
            }).then(redireccionar);
        };

        verificarToken();
    }, []);

    const [datosPanel, setDatosPanel] = useState(null);

    // Llamada a la API cuando el componente se monta
    useEffect(() => {
      const token = sessionStorage.getItem("token");
  
      const fetchDatos = async () => {
        try {
          const response = await fetch(
            "/api/facturacion/bienvenidopanelfacturacion",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: token,
                provincia: provinciaSeleccionada || "", // Enviamos la provincia seleccionada o vacío si no hay filtro
            }),
            }
          );
  
          if (response.ok) {
            const data = await response.json();
            setDatosPanel(data); // Guardar los datos en el estado
            console.log(data);
          } else {
            console.error("Error en la llamada a la API");
          }
        } catch (error) {
          console.error("Error en la llamada a la API", error);
        }
      };
  
      fetchDatos();
    }, [provinciaSeleccionada]);

    useEffect(() => {
        // Función para obtener datos de facturación diaria
        const obtenerDatosFacturacion = async () => {
            const token = sessionStorage.getItem('token');

            if (!token) {
                console.error('No hay token disponible.');
                return;
            }

            setCargando(true);
            try {
                const response = await fetch(apiUrlFacturacion, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Token: token,
                        provincia: provinciaSeleccionada || "", // Enviamos la provincia seleccionada o vacío si no hay filtro
                    }), // Enviar el token en la solicitud
                });

                if (response.ok) {
                    const data = await response.json();
                    setDatosFacturacion(data); // Actualizar el estado con los datos recibidos
                } else {
                    console.error('Error al obtener datos de facturación');
                }
            } catch (error) {
                console.error('Error en la solicitud de facturación diaria:', error);
            } finally {
                setCargando(false);
            }
        };

        obtenerDatosFacturacion();
    }, [provinciaSeleccionada]);

    useEffect(() => {
        const fetchProvincias = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_PROVINCIAS);
                const data = await response.json();
                const provinciasFormateadas = data.map((item) => ({
                    value: item.provincia, // Ajustar según el campo correcto
                    label: item.provincia,
                }));
                setProvincias(provinciasFormateadas); // Guardar las provincias formateadas en el estado
            } catch (error) {
                console.error('Error al obtener las provincias:', error);
            }
        };
        fetchProvincias();
    }, []);
    

    return (
        <>
            <div className='pt-5'>
                <BienvenidaFlor datosPanel={datosPanel}/>
            </div>
            <div className="pt-5">
                <div className="d-flex justify-content-center mb-4">
                    <Select
                        options={provincias} // Usar las provincias formateadas aquí
                        onChange={(opcion) => setProvinciaSeleccionada(opcion ? opcion.value : null)}
                        placeholder="Filtrar por provincia"
                        isClearable
                    />
                </div>
            </div>
            <div>
                <TituloPagina title='Panel de Terminales' />
            </div>
            <div>
                <ContenidoInicio datos={datosFacturacion} />
            </div>
            <div>
                <Footer />
            </div>
        </>
    );
};

export default Inicio;
