import React, { useContext, useState, useEffect } from 'react';
import { FormControl,
    InputGroup,
    Row,
    Col,
    Form,
    Button,
    Card,
    Spinner,
    Modal,
    FormGroup,
    FormLabel,
    Table } from 'react-bootstrap';
import { DarkModeContext } from "../../context/DarkModeContext";
import { ReactComponent as DisponibleIcon } from "../../assets/svg/Recurso 7.svg";
import { ReactComponent as AltaIcon } from "../../assets/svg/Recurso 8.svg";
import { ReactComponent as BajaIcon } from "../../assets/svg/Recurso 9.svg";
import { ReactComponent as ServicioIcon } from "../../assets/svg/Recurso 10.svg";
import { ReactComponent as ExtraviadaIcon } from "../../assets/svg/Recurso 12.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFileInvoice,
    faSearch,
    faUndo,
    faEye,
    faEdit,
    faPlus,
    faTimes,
    faLocationDot,
    faFileExcel
  } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";




const ContenidoInicio = ({ datos = [] }) => {
    const { darkMode } = useContext(DarkModeContext);
    const [filaExtendida, setFilaExtendida] = useState(null);
    const [searchField, setSearchField] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [searchTerms, setSearchTerms] = useState({});
    const [showAll, setShowAll] = useState(true);
    const [loading, setLoading] = useState(false);
    const [searchSubmitted, setSearchSubmitted] = useState(false); // Nuevo estado para controlar si se ha enviado una búsqueda
    const [selectedActivo, setSelectedActivo] = useState(null);


    const [filters, setFilters] = useState({
        searchTerms: {}, // Aquí se guardan los términos de búsqueda (número de terminal, comercio, CUIT, provincia, etc.)
        estado: null, // Aquí se guarda el estado seleccionado
      });

      const handleSearchSubmit = () => {
        if (!searchValue || searchValue.trim().length < 4 || !searchField) {
          setShowAll(true);
          return;
        }
      
        setLoading(true);
        setTimeout(() => {
          // Solo actualizará los filtros cuando se haga clic en el botón
          setFilters((prevFilters) => ({
            ...prevFilters,
            searchTerms: {
              ...prevFilters.searchTerms,
              [searchField]: searchValue.trim(), // Agregar .trim() para eliminar espacios adicionales
            },
          }));
          setShowAll(false);
          setLoading(false);
          setSearchSubmitted(true); // Marcar que la búsqueda ha sido enviada
        }, 1000);
      };

      const selectOptions = [
        { value: "ndeterminal", label: "N° Terminal" },
        { value: "cuit", label: "CUIT/CUIL" },
        //{ value: "cuitrazonsocial", label: "Cuit razón social" },
        // { value: "estado", label: "Estado" },
      
      ];
      const tiempoInactivoOptions = [
        { value: "Activo", label: "Activo" },
        { value: "Baja facturación", label: "Baja facturación" },
        { value: "No factura", label: "No factura" }, // Actualizamos la descripción aquí
      ];
      const [cuitsNoFacturan, setCuitsNoFacturan] = useState([]);
    const [totalCuitsNoFacturan, setTotalCuitsNoFacturan] = useState(0);

    // Calcular los CUITs que no facturan
    useEffect(() => {
        const cuitMap = new Map();

        // Agrupamos por CUIT y verificamos si todas las terminales no están facturando
        datos.forEach((dato) => {
            const cuit = dato.cuit;
            const estadoFacturacion = dato.tiempoInactiva || ''; // Tiempo inactivo de facturación

            if (!cuitMap.has(cuit)) {
                cuitMap.set(cuit, { facturando: false });
            }

            // Si alguna terminal está facturando, marcamos el CUIT como facturante
            if (!/No factura hace \d+ días/.test(estadoFacturacion)) {
                cuitMap.set(cuit, { facturando: true });
            }
        });

        // Filtrar CUITs que no están facturando (todas sus terminales no facturan)
        const cuitsSinFacturar = [...cuitMap.entries()]
            .filter(([, value]) => !value.facturando)
            .map(([cuit]) => cuit);

        setCuitsNoFacturan(cuitsSinFacturar);
        setTotalCuitsNoFacturan(cuitsSinFacturar.length);
    }, [datos]);
      
      const filteredDatos = datos.filter((dato) => {
        // Filtrar por términos de búsqueda
        const matchesSearchTerms = Object.keys(filters.searchTerms).every((field) => {
          const searchValue = filters.searchTerms[field]?.toLowerCase() || '';
          const datoField = dato[field]?.toString().toLowerCase() || '';
      
          // Para palabras exactas (Provincia, Localidad, etc.)
          if (isNaN(searchValue)) {
            return datoField.trim() === searchValue.trim();
          }
      
          // Para números exactos (CUIT, N° de comercio, N° de terminal)
          return datoField === searchValue;
        });
      
        // Filtrar por tiempo inactivo seleccionado
        const matchesTiempoInactivo = (() => {
          if (!filters.tiempoInactivo) return true;
      
          // Si se seleccionó "Activo"
          if (filters.tiempoInactivo === "Activo") {
            return dato.tiempoInactiva === "Activo";
          }
      
          // Si se seleccionó "No factura"
          if (filters.tiempoInactivo === "No factura") {
            // Usamos una expresión regular para detectar "No factura hace X días"
            const regex = /No factura hace \d+ días/;
            return regex.test(dato.tiempoInactiva);
          }
      
          // Otros estados (como "Baja facturación")
          return dato.tiempoInactiva === filters.tiempoInactivo;
        })();
      
        // Retornar verdadero si ambos filtros coinciden
        return matchesSearchTerms && matchesTiempoInactivo;
      });
    
      const handleSelectorChange = (selectedOption) => {
        setSearchField(selectedOption.value);
        setSearchValue("");
      };
    
      const customFilterOption = (option, inputValue) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
      };

     
    const tableRowStyle = {
        transition: "transform 0.2s ease-in-out",
    };

    const tableCellStyle = (isExpanded) => ({
        whiteSpace: isExpanded ? "normal" : "nowrap",
        overflow: isExpanded ? "visible" : "hidden",
        textOverflow: isExpanded ? "clip" : "ellipsis",
        height: isExpanded ? "auto" : "60px",
    });

    const handleFilaClick = (index) => {
        setFilaExtendida(filaExtendida === index ? null : index);
    };

    const getEstadoIcon = (estado) => {
        switch (estado) {
            case "ALTA":
                return <AltaIcon style={{ width: 24, height: 24 }} />;
            case "BAJA":
                return <BajaIcon style={{ width: 24, height: 24 }} />;
            case "SERVICIO.TECNICO":
                return <ServicioIcon style={{ width: 24, height: 24 }} />;
            case "DISPONIBLE":
                return <DisponibleIcon style={{ width: 24, height: 24 }} />;
            case "EXTRAVIADA":
                return <ExtraviadaIcon style={{ width: 24, height: 24 }} />;
            default:
                return estado; // En caso de que no haya un ícono para el estado
        }
    };

    return (
        <div>
            
            
                <div className='container' style={{ marginBottom: "10px" }}>
                 {/* Row para buscar */}
          <Row className="my-3 align-items-center">
            {/* Col para Buscar */}
            <Col xs="auto">
              <Form.Label className="fs-18 lato-bold mb-0" style={{ color: "#292B2F" }}>
                Buscar
              </Form.Label>
            </Col>

            {/* Select para elegir campo de búsqueda */}
            <Col xs="auto">
              <Select
                className="select__control_custom-inicio select__control_custom lato-bold fs-12"
                classNamePrefix="select"
                value={selectOptions.find((option) => option.value === searchField)}
                onChange={handleSelectorChange}
                options={selectOptions}
                isSearchable={true}
                placeholder="Seleccione"

              />
            </Col>

            {/* Campo de búsqueda */}
            <Col xs="auto">
              <FormControl
                className="form-control input-ingresar-buscador-crm px-5 border-0"
                placeholder={`Buscar por ${
                  selectOptions.find((option) => option.value === searchField)?.label ||
                  "Seleccione un campo"
                }`}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                disabled={!searchField}
                style={{ width: "100%" }}
              />
            </Col>

            {/* Botón de búsqueda */}
            <Col xs="auto">
              <Button
                className="btn-publicar border-0 mx-1"
                onClick={handleSearchSubmit}
                disabled={!searchField || searchValue.length < 4}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </Col>
          </Row>

          {/* Fila para filtrar por Tiempo inactivo */}
          <Row className="my-3 align-items-center">
            <Col className="d-flex align-items-center justify-content-start">
              <Form.Label className="fs-18 lato-bold mb-0 me-2">Filtrar por Tiempo Inactivo</Form.Label>
              <Select
                className="select__control_custom-inicio select__control_custom lato-bold fs-12 me-2"
                classNamePrefix="select"
                value={tiempoInactivoOptions.find((option) => option.value === filters.tiempoInactivo)}
                onChange={(option) =>
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    tiempoInactivo: option ? option.value : null,
                  }))
                }
                options={tiempoInactivoOptions}
                isClearable={true}
                placeholder="Seleccione"
              />
            </Col>

            <Col xs="auto">
                    <Form.Label className="fs-18 lato-bold mb-0">
                        Total de CUITs que no facturan: {totalCuitsNoFacturan}
                    </Form.Label>
                </Col>

                <Col xs="auto">
                    <Select
                        className="select__control_custom-inicio select__control_custom lato-bold fs-12"
                        classNamePrefix="select"
                        options={cuitsNoFacturan.map((cuit) => ({ label: cuit, value: cuit }))}
                        placeholder="No facturan"
                        isSearchable={true}
                        isClearable={true}
                    />
                </Col>
          </Row>

                </div>
                <section translate="no" className="container py-analisis-grafica">

                
                <article>
                <div
                    className={
                        darkMode
                            ? "container table-responsive py-3 px-5"
                            : "container table-responsive py-3 px-5"
                    }
                >
                        
                            <Table responsive striped hover>
                                <thead className="bg-dark py-2">
                                    <tr className="text-center tabla-thead">
                                        <th
                                            className={
                                                darkMode
                                                    ? "bg-white text-dark border-tabla-izquierda border-0 lato-regular fs-14 py-3"
                                                    : "bg-dark text-white border-tabla-izquierda border-0 lato-regular fs-14 py-3"
                                            }
                                            scope="col"
                                        >
                                            Estado
                                        </th>
                                        <th
                                            className={
                                                darkMode
                                                    ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                                                    : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                                            }
                                            scope="col"
                                        >
                                            Terminal
                                        </th>
                                        <th
                                            className={
                                                darkMode
                                                    ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                                                    : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                                            }
                                            scope="col"
                                        >
                                            Nombre de Fantasía
                                        </th>
                                        <th
                                            className={
                                                darkMode
                                                    ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                                                    : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                                            }
                                            scope="col"
                                        >
                                            CUIT/CUIL
                                        </th>
                                        <th
                                            className={
                                                darkMode
                                                    ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                                                    : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                                            }
                                            scope="col"
                                        >
                                            Provincia
                                        </th>
                                        <th
                                            className={
                                                darkMode
                                                    ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                                                    : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                                            }
                                            scope="col"
                                        >
                                            Tiempo inactiva
                                        </th>
                                        {/* Columnas para la facturación diaria */}
                                    {datos.length > 0 && Array.from({ length: datos[0].facturacionDiaria.length }, (_, i) => (
                                        <th
                                            key={i}
                                            className={
                                                darkMode
                                                    ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                                                    : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                                            }
                                            scope="col"
                                        >
                                            {`Día ${i + 1}`}
                                        </th>
                                    ))}

                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    {/* Renderizar tabla vacía si no hay datos */}
                                    {filteredDatos.length > 0 ? (
                  filteredDatos.map((dato, index) => (
                                            <tr
                                                className={
                                                    darkMode ? "bg-dark text-white" : "bg-white text-dark"
                                                }
                                                style={tableRowStyle}
                                                onClick={() => handleFilaClick(index)}
                                                key={index}
                                            >
                                                <td
                                                    style={tableCellStyle(filaExtendida === index)}
                                                    className={
                                                        darkMode
                                                            ? "fs-14 lato-regular py-4 prority-4 text-white "
                                                            : "fs-14 lato-regular py-4 prority-4 "
                                                    }
                                                >
                                                    {getEstadoIcon(dato.estado)}
                                                </td>
                                                <td
                                                    style={tableCellStyle(filaExtendida === index)}
                                                    className={
                                                        darkMode
                                                            ? "fs-14 lato-regular py-4 prority-4 text-white "
                                                            : "fs-14 lato-regular py-4 prority-4 "
                                                    }
                                                >
                                                    {dato.nDeTerminal}
                                                </td>
                                                <td
                                                    style={tableCellStyle(filaExtendida === index)}
                                                    className={
                                                        darkMode
                                                            ? "fs-14 lato-regular py-4 prority-4 text-white "
                                                            : "fs-14 lato-regular py-4 prority-4 "
                                                    }
                                                >
                                                    {dato.nombreDeFantasia}
                                                </td>
                                                <td
                                                    style={tableCellStyle(filaExtendida === index)}
                                                    className={
                                                        darkMode
                                                            ? "fs-14 lato-regular py-4 prority-4 text-white "
                                                            : "fs-14 lato-regular py-4 prority-4 "
                                                    }
                                                >
                                                    {dato.cuit ?? "-"}
                                                </td>
                                                <td
                                                    style={tableCellStyle(filaExtendida === index)}
                                                    className={
                                                        darkMode
                                                            ? "fs-14 lato-regular py-4 prority-4 text-white "
                                                            : "fs-14 lato-regular py-4 prority-4 "
                                                    }
                                                >
                                                    {dato.provincia}
                                                </td>
                                                <td
                                                    style={tableCellStyle(filaExtendida === index)}
                                                    className={
                                                        darkMode
                                                            ? "fs-14 lato-regular py-4 prority-4 text-white "
                                                            : "fs-14 lato-regular py-4 prority-4 "
                                                    }
                                                >
                                                    {dato.tiempoInactiva}
                                                </td>
                          {/* Recorriendo la facturación diaria */}
                          {Array.isArray(dato.facturacionDiaria) && dato.facturacionDiaria.length > 0 ? (
                              dato.facturacionDiaria.map((facturacion, i) => (
                                  <td
                                      key={i}
                                      style={tableCellStyle(filaExtendida === index)}
                                      className={
                                          darkMode
                                              ? "fs-14 lato-regular py-4 prority-4 text-white "
                                              : "fs-14 lato-regular py-4 prority-4 "
                                      }
                                  >
                                      {facturacion.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}
                                  </td>
                              ))
                          ) : (
                              <td colSpan={datos[0].facturacionDiaria.length}>
                                  No hay facturación disponible.
                              </td>
                          )}
                      </tr>
                  ))
                                ) : (
                                    <tr>
                                       
                                    </tr>
                                )
                                }

                                </tbody>
                            </Table>
                        </div>
                    
                </article>
                </section>
                
            
        </div>
    );
};

export default ContenidoInicio;
