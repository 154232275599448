import React, { useState, useEffect, useContext } from "react";
import { DarkModeContext } from "../../context/DarkModeContext";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx"; // Importar XLSX

const TablaCRMInterno = () => {
  const [datos, setDatos] = useState([]); // Estado para los datos del CRM
  const [todosLosDatos, setTodosLosDatos] = useState([]); // Estado para almacenar todos los datos sin paginar
  const [fecha, setFecha] = useState(new Date().toISOString().split("T")[0]); // Estado para la fecha (por defecto hoy)
  const [paginaActual, setPaginaActual] = useState(1); // Estado para la paginación
  const [totalPaginas, setTotalPaginas] = useState(1); // Estado para el total de páginas
  const registrosPorPagina = 10; // Número de registros por página
  const { darkMode } = useContext(DarkModeContext);
  const [filaExtendida, setFilaExtendida] = useState(null);
  const [filtroEstado, setFiltroEstado] = useState("todos"); // Estado para el filtro de estado

  const tableRowStyle = {
    transition: "transform 0.2s ease-in-out",
  };

  // const tableRowHoverStyle = {
  //   transform: "scale(1.01)",
  //   zIndex: 1,
  // };

  const tableCellStyle = (isExpanded) => ({
    whiteSpace: isExpanded ? "normal" : "nowrap",
    overflow: isExpanded ? "visible" : "hidden",
    textOverflow: isExpanded ? "clip" : "ellipsis",
    height: isExpanded ? "auto" : "60px",
  });

  // const tableCellHoverStyle = {
  //   whiteSpace: "normal",
  //   overflow: "visible",
  // };

  const handleFilaClick = (index) => {
    setFilaExtendida(filaExtendida === index ? null : index);
  };

  useEffect(() => {
    // Función para obtener los datos desde la API
    const obtenerDatos = async (fechaSeleccionada, pagina) => {
      const token = sessionStorage.getItem("token");

      try {
        const response = await fetch("/api/calidad/listadias", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Token: token,
            Fecha: fechaSeleccionada,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          setTodosLosDatos(data);
          console.log(data);
          // Dividir los datos en páginas
          const inicio = (pagina - 1) * registrosPorPagina;
          const fin = inicio + registrosPorPagina;
          setDatos(data.slice(inicio, fin)); // Guardamos los primeros 10 resultados
          setTotalPaginas(Math.ceil(data.length / registrosPorPagina)); // Calculamos el total de páginas
        } else {
          console.error("Error al obtener los datos");
        }
      } catch (error) {
        console.error("Error en la conexión", error);
      }
    };

    obtenerDatos(fecha, paginaActual); // Llamada a la API por defecto al cargar el componente
  }, [fecha, paginaActual]); // Actualizar cuando la fecha o la página cambien

  // Manejar cambio de fecha
  const handleFechaChange = (e) => {
    setFecha(e.target.value);
    setPaginaActual(1); // Resetear a la primera página cuando se cambia la fecha
  };

  // Cambiar página
  const cambiarPagina = (nuevaPagina) => {
    if (nuevaPagina > 0 && nuevaPagina <= totalPaginas) {
      setPaginaActual(nuevaPagina);
    }
  };
  const exportarExcel = () => {
    // Crea un libro de trabajo
    const workbook = XLSX.utils.book_new();
    // Convierte los datos en una hoja de trabajo
    const worksheet = XLSX.utils.json_to_sheet(todosLosDatos);
    // Agrega la hoja de trabajo al libro
    XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");
    // Genera el archivo Excel y lo descarga
    XLSX.writeFile(workbook, `DatosCRM_${fecha}.xlsx`);
  };

  const handleFiltroChange = (e) => {
    setFiltroEstado(e.target.value);
    setPaginaActual(1);
  };
  const datosFiltrados = todosLosDatos.filter((dato) => {
    if (filtroEstado === "todos") return true;
    if (filtroEstado === "cerradas")
      return dato.estado === "Cerrado" || dato.estado === "Cerrada";
    if (filtroEstado === "pendiente")
      return dato.estado !== "Cerrado" && dato.estado !== "Cerrada";
    return true;
  });

  return (
    <section
      className={
        darkMode
          ? "container cuadro-calificaciones-dark"
          : "container cuadro-calificaciones"
      }
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        marginTop: "20px",
      }}
    >
      <div
        className={
          darkMode
            ? "container table-responsive py-3 px-5"
            : "container table-responsive py-3 px-5"
        }
      >
        {/* Selector de fecha */}

        {/* Tabla de resultados */}
        <article>
          <div
            className={
              darkMode
                ? "container table-responsive py-3 px-5"
                : "container table-responsive py-3 px-5"
            }
          >
            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%", // Ocupa todo el ancho del contenedor
                marginBottom: "10px",
                gap: "10px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <label
                  htmlFor="fecha"
                  className="lato-regular fs-16"
                  style={{
                    display: "flex", // Para alinear icono y texto
                    alignItems: "center", // Centra verticalmente el texto y el icono
                    marginRight: "10px", // Espacio entre el label y el input
                    height: "44px", // Asegura que la altura sea igual al botón
                  }}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    style={{ marginRight: "5px", color: "#89939F" }}
                  />{" "}
                  Fecha:
                </label>
                <input
                  type="date"
                  id="fecha"
                  className="form-control"
                  value={fecha}
                  onChange={handleFechaChange}
                  style={{
                    width: "auto", // Ajusta el tamaño del input a lo necesario
                    height: "44px", // Ajusta la altura para que coincida con la del botón
                    background: "#DCDEE1",
                    marginRight: "15px", //
                    borderRadius:"32px"
                  }}
                />
                <div style={{ marginRight: "10px" }}>
                  <select
                    value={filtroEstado}
                    onChange={handleFiltroChange}
                    className="form-control"
                    style={{
                      height: "44px",
                      background: "#DCDEE1",
                      borderRadius:"32px"
                    }}
                  >
                    <option value="todos">Todos</option>
                    <option value="cerradas">Cerradas</option>
                    <option value="pendiente">Pendiente</option>
                  </select>
                </div>

                <div style={{ textAlign: "right" }}>
                  <button
                    className="btn"
                    onClick={exportarExcel}
                    style={{
                      width: "200px", // Ajuste del ancho del botón, haciéndolo más grande
                      height: "44px",
                      background: "#B4C400",
                      color: "#FFFFFF",
                      borderRadius:"32px"
                    }}
                  >
                    <FontAwesomeIcon className="me-2" icon={faFileExcel} />
                    Descargar Excel
                  </button>
                </div>
              </div>
            </div>
            <Table table table-borderless responsive striped hov>
              <thead className="bg-dark py-2">
                <tr className="text-center tabla-thead">
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla-izquierda border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla-izquierda border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    ID
                  </th>
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Nombre Usuario
                  </th>{" "}
                  {/* Nueva columna para el nombre */}
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Fecha
                  </th>
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Hora
                  </th>
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Canal de Atención
                  </th>
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Tipo
                  </th>
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Aliado
                  </th>
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Motivo
                  </th>
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Estado
                  </th>
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla-derecha border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla-derecha border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Observaciones
                  </th>
                </tr>
              </thead>
              <tbody className="text-center">
                {datosFiltrados.length > 0 ? (
                  datosFiltrados
                    .slice(
                      (paginaActual - 1) * registrosPorPagina,
                      paginaActual * registrosPorPagina
                    )
                    .map((dato, index) => (
                      <tr
                        className={
                          darkMode ? "bg-dark text-white" : "bg-white text-dark"
                        }
                        style={tableRowStyle}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.transform =
                            // tableRowHoverStyle.transform;
                            e.currentTarget.style.overflow = "hidden"; // Asegura que no se muestre scroll lateral
                        }}
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.transform = "scale(1)")
                        }
                        onClick={() => handleFilaClick(index)}
                        key={index}
                      >
                        <td
                          style={tableCellStyle(filaExtendida === index)}
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white "
                              : "fs-14 lato-regular py-4 prority-4 "
                          }
                        >
                          {dato.id}
                        </td>
                        <td
                          style={tableCellStyle(filaExtendida === index)}
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white "
                              : "fs-14 lato-regular py-4 prority-4 "
                          }
                        >
                          {dato.nombreUsuario}
                        </td>{" "}
                        {/* Mostrar el nombre del usuario */}
                        <td
                          style={tableCellStyle(filaExtendida === index)}
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white "
                              : "fs-14 lato-regular py-4 prority-4 "
                          }
                        >
                          {new Date(dato.fechaCarga).toLocaleDateString()}
                        </td>
                        <td
                          style={tableCellStyle(filaExtendida === index)}
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white "
                              : "fs-14 lato-regular py-4 prority-4 "
                          }
                        >
                          {new Date(dato.fechaCarga).toLocaleTimeString()}
                        </td>
                        <td
                          style={tableCellStyle(filaExtendida === index)}
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white "
                              : "fs-14 lato-regular py-4 prority-4 "
                          }
                        >
                          {dato.canalAtencion}
                        </td>
                        <td
                          style={tableCellStyle(filaExtendida === index)}
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white "
                              : "fs-14 lato-regular py-4 prority-4 "
                          }
                        >
                          {dato.tipo}
                        </td>
                        <td
                          style={tableCellStyle(filaExtendida === index)}
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white "
                              : "fs-14 lato-regular py-4 prority-4 "
                          }
                        >
                          {dato.aliado}
                        </td>
                        <td
                          style={tableCellStyle(filaExtendida === index)}
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white "
                              : "fs-14 lato-regular py-4 prority-4 "
                          }
                        >
                          {dato.motivo}
                        </td>
                        <td
                          style={tableCellStyle(filaExtendida === index)}
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white "
                              : "fs-14 lato-regular py-4 prority-4 "
                          }
                        >
                          {dato.estado}
                        </td>
                        <td
                          style={tableCellStyle(filaExtendida === index)}
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white "
                              : "fs-14 lato-regular py-4 prority-4 "
                          }
                        >
                          {dato.observaciones}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="9">
                      No hay datos disponibles para la fecha seleccionada.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </article>

        {/* Paginación */}
        <div className="d-flex justify-content-between">
          <button
            className="btn"
            style={{
              borderRadius: "23px",
              top: "848px",
              left: "1343px",
              width: "182px",
              height: "44px",
              background: "#B4C400",
              color: "#FFFFFF",
              marginTop: "10px",
            }}
            onClick={() => cambiarPagina(paginaActual - 1)}
            disabled={paginaActual === 1}
          >
            Anterior
          </button>
          <span>
            Página {paginaActual} de {totalPaginas}
          </span>
          <button
            className="btn"
            style={{
              borderRadius: "23px",
              top: "848px",
              left: "1343px",
              width: "182px",
              height: "44px",
              background: "#B4C400",
              color: "#FFFFFF",
              marginTop: "10px",
            }}
            onClick={() => cambiarPagina(paginaActual + 1)}
            disabled={paginaActual === totalPaginas}
          >
            Siguiente
          </button>
        </div>
      </div>
    </section>
  );
};

export default TablaCRMInterno;
