import "./BienvenidaFlor.css";

import React, { useContext, useEffect, useState } from "react";
import { DarkModeContext } from "../../context/DarkModeContext";
import {
  faCheckCircle,
  faCircleArrowDown,
  faCircleArrowUp,
  faMagnifyingGlassChart,
  faRoute,
  faToggleOn,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



const BienvenidaFlor = ({datosPanel}) => {
  const { darkMode } = useContext(DarkModeContext);

  // Estado para almacenar los datos de la API
 
  return (
    <section className="container">
      <section className="d-lg-block d-none">
        <div
          className={
            darkMode
              ? "py-4  contenedor-panel-control-asesores-dark container d-none d-xl-block"
              : "py-4  contenedor-panel-control-asesores container d-none d-xl-block"
          }
        >
          <div className="d-flex justify-content-evenly py-3">
            <article>
              <div className="">
                <h6 className="text-center heavy-900 fs-16 ms-2">
                  {" "}
                  Bienvenido/a <br />{" "}
                  <span className="text-center heavy-900 color-verde fs-20 line-h-26">
                    {" "}
                    Florencia
                  </span>{" "}
                </h6>

                   
              </div>
              
            </article>
            <article className="borde-caja-panel"></article>
            {datosPanel ? (
              <>
              <article>
              <div className="text-center">
                <span className="lato-regular fs-16"> Stock de terminales</span>{" "}
                <br />
                <span className="fs-25 lato-bold color-verde">{datosPanel.totalTerminales}</span>
              </div>
            </article>
            <article className="borde-caja-panel"></article>
            <article>
              <div>
                <span className="lato-bold  fs-16"> Total de terminales en Altas</span> <br />
                <div className="lato-bold">
                  <FontAwesomeIcon
                    className="me-4 fs-25 color-verde"
                    icon={faCircleArrowUp}
                  />
                  <span className="fs-25"> {datosPanel.totalTerminalesAlta}</span>
                </div>
              </div>
            </article>
            <article className="borde-caja-panel"></article>
            <article>
              <div className="color-verde">
                <span className="lato-bold  fs-16"> Facturan</span> <br />
                <div className="lato-bold color-verde  ">
                  <FontAwesomeIcon className="me-4 fs-25" icon={faToggleOn} />
                  <span className="fs-25"> {datosPanel.totalFacturan}</span>
                </div>
              </div>
            </article>
            <article className="borde-caja-panel"></article>
            <article>
              <div className="color-verde">
                <span className="lato-regular fs-16"> Terminales Nuevas</span>{" "}
                <br />
                <div className="lato-bold   ">
                <FontAwesomeIcon
                    className="me-4 fs-25 color-verde"
                    icon={faCircleArrowUp}
                  />
                  <span className="fs-25"> {datosPanel.totalTerminalesNuevas}</span>
                </div>
              </div>
            </article>
            <article className="borde-caja-panel"></article>
            <article>
              <div className="color-rojo">
                <span className="lato-regular fs-16"> No facturan</span> <br />
                <div className="lato-bold  ">
                  <FontAwesomeIcon className="me-4 fs-25" icon={faRoute} />
                  <span className="fs-25"> {datosPanel.totalNoFacturan}</span>
                </div>
              </div>
            </article>
            <article className="borde-caja-panel"></article>
            <article>
              <div className="color-naranja ">
                <span className="lato-regular fs-16"> Baja facturacion</span> <br />
                <div className="lato-bold  ">
                  <FontAwesomeIcon
                    className="me-4 fs-25"
                    icon={faCheckCircle}
                  />
                  <span className="fs-25"> {datosPanel.totalBajaFacturacion}</span>
                </div>
              </div>
            </article>
            <article className="borde-caja-panel"></article>
            <article>
              <div>
                <span className="lato-bold fs-16"> Bajas</span> <br />
                <div className="lato-bold   ">
                  <FontAwesomeIcon
                    className="me-4 fs-25 color-rojo"
                    icon={faCircleArrowDown}
                  />
                  <span className="fs-25"> {datosPanel.totalTerminalesBaja}</span>
                </div>
              </div>
            </article>
            </>
            ) : (
              // Opcional: Un spinner o mensaje de carga mientras los datos se cargan
              <div>Cargando datos...</div>
            )}
          </div>
        </div>
      </section>
    </section>
  );
};

export default BienvenidaFlor;
